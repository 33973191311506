import { useBase } from "@/use/base"
import { reactive, ref } from "vue"
import { index } from "@/api/sysLog"
export function useSysLog() {
    const searchData = reactive({})
    const headers = [{
        label: "ID",
        prop: "Id",
    },
    {
        label: "时间",
        prop: "time",
    },
    {
        label: "类型",
        prop: "type",
    }
        ,
    {
        label: "操作人",
        prop: "user_name",
    },
    {
        label: "ip地址",
        prop: "ip",
    },
    {
        label: "备注",
        prop: "remark",
    }]
    const { tableObjet, search } = useBase(index, searchData,true,true)
    return { search, searchData, tableObjet, headers }
}