<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search" />
        <TableComponent :headers="headers" :tableObjet="tableObjet">
            <template #caozuo="scope">

                <div v-if="(scope.row.state == 1)">

                    <el-button @click="commit(scope.row,true)">通过</el-button>
                    <el-button @click="commit(scope.row,false)">驳回</el-button>
                </div>
            </template>
            <template #state="scope">
            
            {{getState(scope.row.state)}}
            </template>
        </TableComponent>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useChangeCar } from "@/use/order"
const { search, headers, tableObjet, searchData,commit } = useChangeCar();
const getState=(state)=>{
const states=["","待审核","已通过","已驳回"]
return states[state]
}
</script>

<style lang="scss" scoped>

</style>