import axios from "axios"
import {$post} from "./base"
export async function getMenuList(isShowAll=false){
   
    let data=await $post("/menu/index",{isShowAll});
    
    return data;

}
export async function addMenu(form){
    const token=localStorage.getItem("token");
    let {data}=await axios.post("/menu/add",{...form,token})
    return data;
}