
import {$post} from "./base"
export async function addBillingRule(params){
   const data= await $post("/billingrule/add",params)
   return data;
}
export async function getOne(item_model_id){
    let params=new FormData();
    params.append('item_model_id',item_model_id);
    const data= await $post("/billingrule/getone",params)
    return data;
 }

 export async function getBillingRules(store_id){
    let params=new FormData();
    params.append("store_id",store_id)
    const data= await $post("/billingrule/getBillingRules",params)
    return data;
 }
 export async function getBillingRulesOptions(store_id){
   let params=new FormData();
   params.append("store_id",store_id)
   const data= await $post("/billingrule/getBillingRulesOptions",params)
   return data;
}
export function updateBillingRule(params){
return $post("/billingrule/updateBillingRule",params)
}

 
 
