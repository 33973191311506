import { reactive, watch,ref } from "vue";
import {useGetAdminListForOption} from "@/use/admin"
import {useGetStoreList} from "@/use/store"
import {useGetItemModelList} from "@/use/itemModel"
import {add,index} from "@/api/leaseItem"
import { ElMessage } from "element-plus";
import {useBillingRulesOption} from "@/use/billingRule"
import {useBase} from "@/use/base"
export function useLeaseItem(){
    const admin_id=ref(0);
    const store_id=ref(0);
    const {admimOption}=useGetAdminListForOption(2);
    const {stores_option}=useGetStoreList(admin_id);
    const {itemModelList_option}=useGetItemModelList(store_id)
    const {billingRule_options}=useBillingRulesOption(store_id)
    const formData=reactive({
        store_id:{
            label:"选择门店",
            value:"",
            type:"select",
            options:stores_option,
            placeholder:"请选择门店",
            require:true
        },
        item_model:{
            label:"选择物品型号",
            value:"",
            type:"select",
            placeholder:"请选择物品型号",
            options:itemModelList_option,
            require:true
        },
        billing_rule:{
            label:"选择计费规则",
            value:"",
            type:"select",
            placeholder:"请选择计费规则",
            options:billingRule_options,
            require:true
        },
        item_number:{
            label:"物品编号",
            value:"",
            type:"text",
            require:true,
            placeholder:"请输入物品编号"
        },
        device_number:{
            label:"设备号",
            value:"",
            type:"text",
            require:true,
            placeholder:"请输入设备号"
        },
        device_car_number:{
            label:"车架号",
            value:"",
            type:"text",
            require:true,
            placeholder:"请输入车架号"
        }
    })

    

    watch(()=>formData.store_id.value,(i)=>{
        store_id.value=i,
        formData.item_model.options=[];
        formData.item_model.value=null
       

    })
    const createdLeaseItem=async (params)=>{
        let data=await add(params);
        if(data.code==200){
            ElMessage.success(data.msg)
        }else{
            ElMessage.error(data.msg)
        }
    }
    return {formData,createdLeaseItem}
}
export function useLeaseItemList(){
    const headers=ref([
        {
            label:"ID",
            prop:"id"
        },
        {
            label:"所属门店",
            prop:"name"
        },
        {
            label:"物品型号",
            prop:"model"
        },
        // {
        //     label:"押金",
        //     prop:"deposit"
        // },
        {
            label:"计费规则",
            prop:"rule_name"
        },
        // {
        //     label:"租赁类型",
        //     prop:"type"
        // },
        {
            label:"30天租金",
            prop:"price"
        },
        {
            label:"15天租金",
            prop:"d15_price"
        },
        {
            label:"物品编号",
            prop:"item_number"
        },
        {
            label:"设备编号",
            prop:"device_number"
        },
        {
            label:"车架号",
            prop:"device_car_number"
        },
        {
            label:"二维吗",
            prop:"qrcode"
        }
        ,
        {
            label:"租赁状态",
            prop:"state"
        },
        {
            label:"能否出租/使用",
            prop:"lock_state"
        },
        {
            label:"操作",
            prop:"caozuo"
        }
    ])
    const searchData=reactive({
        device_number:{
            label:"设备编号",
            type:"text",
            placeholder:"输入设备编号"
        },
        state:{
            label:"租赁状态",
            type:"select",
            placeholder:"选择运营状态",
            options:[
                {
                    label:"闲置",
                    value:0
                },
                {
                    label:"租赁中",
                    value:1
                }
            ]
        }
    })
  
   
    const {tableObjet,search}=useBase(index,searchData,false,true)
    return {searchData,headers,tableObjet,search}
}