
import { getItemModelList } from "@/api/itemModel"
import { ElMessage } from "element-plus";
import { ref, watch, reactive, watchEffect } from "vue"
import { useGetStoreList } from "@/use/store"
import { addItemModel } from "@/api/itemModel"
import { useGetAdminListForOption } from "@/use/admin"
export function useGetItemModelList(store_id) {
    if (!store_id) {
        store_id = ref(0);
    }
    const itemModelList = ref([]);
    const itemModelList_option = ref([]);
    
    const { stores_option } = useGetStoreList();

    if (!store_id) {
        get(0);
    }
    watch(store_id, () => {
        get(store_id.value);
    })
    function get(i) {
        getItemModelList(i)
            .then(data => {
                if (data.code == 200) {
                    itemModelList.value = data.data;
                    itemModelList_option.value = data.data.map(i => {
                        return { label: i.model, value: i.id }
                    })
                    console.log(itemModelList_option);
                } else {
                    ElMessage.error(data.msg);
                }
            })
    }
    const remvoe = (i) => {
        ElMessage.warning("暂未实现!");
    }

    const searchData = reactive(
        {
            store_id: {
                label: '选择门店',
                type: 'select',
                placeholder: "选择门店",
                value: "",
                options: stores_option
            }
        }
    )

    watchEffect(()=>{
        
        searchData.store_id.value="";
        searchData.store_id.options=[];
    })
    watchEffect(()=>{
        
        store_id.value=searchData.store_id.value;
    })
    const searchItemModel=()=>{
        
    }
   
    return { itemModelList, itemModelList_option, remvoe, searchData,searchItemModel}
}


export function useAddItemModel() {
    
    const { stores_option } = useGetStoreList();

    const data = reactive({
        store_id: {
            type: "select",
            label: "所属门店",
            value: "",
            require: true,
            placeholder: "请选择门店",
            options: stores_option
        },
        model: {
            type: "text",
            label: "物品型号",
            value: "",
            require: true,
            placeholder: "请输入物品型号"
        },
        type: {
            type: "select",
            label: "物品类目",
            value: "",
            require: true,
            placeholder: "请选择物品类目",
            options: [
                {
                    label: "电动车",
                    value: 1
                },
                {
                    label: "电池",
                    value: 2
                }
            ]
        },
        deposit: {
            placeholder: "请输入物品的押金",
            type: "text",
            label: "押金",
            value: "",
            require: true
        },
        v: {
            placeholder: "请输入电池电压",
            type: "text",
            label: "电池电压",
            value: "",
            require: true
        },
        a: {
            placeholder: "请输入电池电流",
            type: "text",
            label: "电池电流",
            value: "",
            require: true
        },
        mah: {
            placeholder: "请输入电池容量",
            type: "text",
            label: "电池容量",
            value: "",
            require: true
        },
        description: {
            placeholder: "请输入物品的租赁说明",
            type: "text",
            label: "说明",
            value: "",
            require: true
        },
        feature: {
            placeholder: "请输入物品的特点，简介",
            type: "text",
            label: "特点",
            value: "",
            require: true
        },
        // big_img:{
        //      placeholder:"请选择物品的大图",
        //     type:"file",
        //     label:"物品大图",
        //     value:"",
        //     name:"big_img",
        //     require:true
        // },
        // min_img:{
        //      placeholder:"请选择物品的略缩图",
        //     type:"file",
        //     label:"物品略缩图",
        //     value:"",
        //     name:"min_img",
        //     require:true
        // }
    })
    const submit = (async (params) => {
        const { code, msg } = await addItemModel(params);
        if (code == 200) {
            ElMessage.success({ type: "success", message: msg })
        } else {
            ElMessage.error(msg)
        }
    })
    return { data, submit }
}