import {freezeCash, adminCash, adminCashFlow, cashRule, updateCashRule, cashReview, cashApply, cashReviewConfirm } from "@/api/finance"
import { reactive, ref } from "vue"
import { useBase, useHandleEdit } from "@/use/base"
import { ElMessage } from "element-plus";
/**
 * 商户资金列表
 * 
 */
export function useAdminCash() {
    const headers = [
        {
            label: "ID",
            prop: "id"
        },
        {
            label: "门店名",
            prop: "name"
        },
        {
            label: "可提现资金余额",
            prop: "balance"
        },
        {
            label: "待解冻资金",
            prop: "freeze_balance"
        }
    ]
    const data = ref([]);

    adminCash().then(res => data.value = res.data)

    return { headers, data }
}

/**
 * 
 * 商户资金流水
 */
export function useAdminCahFlow() {

    const searchData = reactive({})
    const headers = [
        {
            label: "ID",
            prop: "id"
        },
        {
            label: "门店",
            prop: "name"
        },
        {
            label: "变动前金额",
            prop: "balance_pre"
        },
        {
            label: "变动金额",
            prop: "price"
        },
        {
            label: "变动后金额",
            prop: "balance_post"
        },
        {
            label: "类型",
            prop: "type"
        },
        {
            label: "变动时间",
            prop: "create_time"
        },
        {
            label: "备注",
            prop: "remark"
        }

    ]

    const { tableObjet, search } = useBase(adminCashFlow, searchData, false, true)
    // adminCashFlow().then(res => data.value = res.data)

    return { searchData, headers, tableObjet, search }
}
/**
 * 商户提现配置
 */
export function useCashRule() {
    const searchData = reactive({
        name: {
            label: "门店名",
            type: "text",
            value: "",
            placeholder: "输入门店名"
        },
        account_name: {
            label: "收款户名",
            type: "text",
            value: "",
            placeholder: "输入收款户名"
        },
        account_number: {
            label: "收款账号",
            type: "text",
            value: "",
            placeholder: "输入收款账号"
        }
    })
    const headers = [{
        label: "ID",
        prop: 'Id'
    },
    {
        label: "门店名",
        prop: 'name'
    },

    {
        label: "收款户名",
        prop: "account_name"
    },

    {
        label: "收款账号",
        prop: "account_number"
    },
    {
        label: "收款方式",
        prop: "pay_type"
    },
    {
        label: "提现费率",
        prop: "fee"
    },
    {
        label: "费率类型",
        prop: "fee_type"
    },
    {
        label: "操作",
        prop: "caozuo"
    }
    ]
    const { search, tableObjet } = useBase(cashRule, searchData);
    const { handleEtid } = useHandleEdit();
    const edit = (item) => {
        const formData = reactive({
            id: {
                value: item.Id,
                canEdit: false,
                label: "ID",
                type: 'text'

            },
            admin_id: {
                value: item.user_name,
                canEdit: false,
                label: "商户名",
                type: 'text'

            },
            account_name: {
                value: item.account_name,
                canEdit: true,
                label: "收款账户名",
                type: 'text'

            },
            account_number: {
                value: item.account_number,
                canEdit: true,
                label: "收款账户号",
                type: 'text'

            },
            pay_type: {
                value: item.pay_type,
                canEdit: true,
                label: "支付类型",
                type: 'select',
                options: [{
                    label: "微信",
                    value: 1
                },
                {
                    label: "支付宝",
                    value: 2
                }]

            },
            fee: {
                value: item.fee,
                canEdit: true,
                label: "提现手续费",
                type: 'text'

            },
            fee_type: {
                value: item.fee_type,
                canEdit: true,
                label: "费率类型",
                type: 'select',
                options: [{
                    label: "百分比",
                    value: 1
                },
                {
                    label: "固定",
                    value: 2
                }]

            },
        })
        handleEtid(formData, updateCashRule, "修改提现规则")
    }
    return { search, tableObjet, searchData, headers, edit }
}

/**prop
 * 商户提现审核
 */
export function useCashReview() {
    const searchData = reactive({
        name: {
            value: "",
            placeholder: "输入门店名",
            type: "text",
            label: "门店"
        },
        state: {
            value: "",
            placeholder: "选择状态",
            type: "select",
            label: "审核状态",
            options: [
                {
                    label: "未审核",
                    value: 1
                },
                {
                    label: "已完成",
                    value: 2
                },
                {
                    label: "已拒绝",
                    value: 2
                }
            ]

        },
        create_time: {
            value: "",
            type: "datePick",
            label: "申请时间"
        },

    })
    const headers = [{
        label: "id",
        prop: "Id"
    },
    {
        label: "门店",
        prop: "name"
    },
    {
        label: "提现金额",
        prop: "balance"
    },
    {
        label: "收款户名",
        prop: "account_name"
    },
    {
        label: "收款账号",
        prop: "account_number"
    },
    {
        label: "提现金额",
        prop: "balance"
    },
    {
        label: "状态",
        prop: "state"
    },
    {
        label: "申请时间",
        prop: "create_time"
    },
    {
        label: "审核时间",
        prop: "review_time"
    },
    {
        label: "操作",
        prop: "caozuo"
    }];
    const editHandle = (item, status) => {

        cashReviewConfirm({ id: item.Id, status }).then(res => {
            if (res.code == 200) {
                ElMessage.success(res.msg)
            } else {
                ElMessage.error(res.msg)
            }
        })
    }
    const { search, tableObjet } = useBase(cashReview, searchData)
    return { search, searchData, headers, tableObjet, editHandle }
}
/**
 * 提现申请
 */
export function useCashApply() {
    const submit = (formData) => {
        cashApply(formData).then(res => {
            if (res.code == 200) {
                ElMessage.success(res.msg)
            } else {
                ElMessage.error(res.msg)
            }
        })

    }
    const data = reactive({
        balance: {
            label: "提现金额",
            type: "text",
            require: true,
            value: ""
        }
    })
    return { submit, data }


}

export function useFreezeCash(){
 const searchData=reactive({})
const headers=[{
    label:"id",
    prop:"Id"
},
{
    label:"门店",
    prop:"name"
}
,
{
    label:"金额",
    prop:"balance"
}

,
{
    label:"关联订单号",
    prop:"order_num"
}
,{
    label:"创建日期",
    prop:"create_time"
},
{
    label:"解冻日期",
    prop:"free_time"
},
{
    label:"操作",
    prop:"caozuo"
}]
 const {search,tableObjet}=useBase(freezeCash,searchData,null,true)

return {headers,searchData,search,tableObjet}
}