<template>
    <div class="userindex">
        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">
            账户列表
        </div>
        <el-table :data="userList" style="width: 100%;min-height: 600px;" :stripe="true" :border="true">
            <el-table-column prop="id" label="ID" width="180" />
            <el-table-column prop="user_role" label="账户类型" width="180" />
            <el-table-column prop="user_name" label="账户名" width="180" />
            <el-table-column prop="phone" label="联系电话" />
            <el-table-column prop="state" label="账户状态">
                <template #default="scope">

                    <el-switch v-model="scope.row.state" disabled
                        style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="创建时间" />
            <el-table-column prop="id">
                <template #header>
                    操作
                </template>
                <template #default="scope">
                   
                    <el-button @click="handleEdit(scope.$index, scope.row)" type="primary" icon="Edit" circle />
                    <el-button type="danger" icon="Delete" circle @click="handleDelete(scope.$index, scope.row)" />
                </template>
            </el-table-column>

        </el-table>
        <el-pagination background layout="prev, pager, next" :total="dataCount" v-model:current-page="currPage" />
    </div>
</template>

<script setup>
import { getUserList } from "@/api/admin.js"
import { onMounted, ref, reactive, watch, inject } from "vue"
import XIASearchBar from "@/components/XIA-SearchBar.vue"
import { useGetAdminListForOption } from "@/use/admin"
import { getUserInfo, updateUserInfo } from "@/api/admin"
import { useRule } from "@/use/rule"
const { ruleList_option } = useRule();
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")

const { admimOption } = useGetAdminListForOption(2)
//数据总量
const dataCount = ref(0)
const currPage = ref(1)
//用户列表
const userList = ref([])
//搜索框的数据
const searchData = reactive({
    user_name: {
        label: "账户名",
        type: 'text',
        placeholder: "输入账户名",
        value: ""
    },
    phone: {
        label: '手机号',
        type: 'number',
        placeholder: "输入手机号",
        value: ""

    },
    state: {
        label: '商户状态',
        type: 'select',
        placeholder: "选择商户状态",
        value: "",
        options: [
            {
                label: '正常',
                value: 1
            },
            {
                label: '禁用',
                value: 2
            }
        ]
    }
})
const params = reactive({})
//当点击搜索的时候
const search = async () => {
    await getData();
}
onMounted(async () => {
    await getData()
})
watch(currPage, async () => {
    await getData()
})
const getData = async () => {
    for (let i in searchData) {
        params[i] = searchData[i].value;
    }
    params['currPage'] = currPage.value;
    const data = await getUserList(params);
    if (data.code == 200) {
        userList.value = data.data.data;
        for(let i in userList.value){
            userList.value[i].state=userList.value[i].state==1?true:false
        }
        dataCount.value = data.data.dataCount;
    }
}
const handleInfo = (index, item) => {
    drawOptions.isEdit = false
    drawOptions.getApi = getUserInfo
    //drawOptions.postApi=updateUserInfo
    drawOptions.id = item.id
    isOpenDrawer.value = true
}
const handleEdit = (index, item) => {
    const formData = reactive({
        id: {
            label: "ID",
            placeholder: "",
            value: item.id,
            canEdit: false,
            type: "text"
        },
        user_name: {
            label: "账号名",
            placeholder: "请输入账号名",
            value: item.user_name,
            canEdit: true,
            type: "text"
        },
        role_id: {
            label: "账户类型",
            placeholder: "请选择账户类型",
            value: item.role_id,
            options: ruleList_option,
            canEdit: false,
            type: "select"
        },
        phone: {
            label: "联系方式",
            placeholder: "请输入联系方式",
            value: item.phone,
            canEdit: true,
            type: "text"
        },
        state: {
            label: "账号状态",
            value: item.state == 1 ? true : false,
            placeholder: "点击开启或者禁用该用户",
            canEdit: true,
            type: "switch"
        },
        create_time: {
            label: "创建时间",
            value: item.create_time,
            canEdit: false,
            type: "text"
        },
        pass_word: {
            label: "修改密码",
            value: "",
            canEdit: true,
            placeholder: "请输入新密码，不修改不需要输入",
            type: "password"
        }
    })
    drawOptions.isEdit = true
    //drawOptions.getApi=getUserInfo
    drawOptions.postApi = updateUserInfo
    drawOptions.formData = formData
    drawOptions.title = "修改用户信息"
    isOpenDrawer.value = true
}
</script>
<style scoped lang="scss">
.normal {
    color: var(--el-color-primary);
}

.ban {
    color: #f00;
}
</style>