<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search"></XIASearchBar>
        <div class="title">订单列表</div>
        <TableComponent :headers="headers" :tableObjet="tableObjet">
        <template #state="item">
            
            {{getState(item.row.state)}}
        </template>
        <template #type="item">
            {{item.row.type==1?"押金":"租金"}}
        </template>
        <template #caozuo="item">
            <el-button v-if="item.row.state==1" @click="pay_review(item.row)">
                确认支付
            </el-button>
        </template>
        </TableComponent>
    </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useOrderIndex} from "@/use/order"
const {searchData,headers,tableObjet,pay_review,search}=useOrderIndex()
const getState=(i)=>{
    const states=['','未支付',"已支付",'超期未还','已完成','已取消']
    return states[i]
}
</script>
<style lang="scss" scoped>
</style>