import axios from "axios"
import {$post} from "./base"
export async function getUserList(params){
    return $post("/admin/index",params);
}
/**
 * 获取账户信息
 * @param {*} params 
 * @returns 
 */
export async function getUserInfo(params){
    return $post("/admin/userInfo",params);
}
/**
 * 更新账户信息
 * @param {*} params 
 * @returns 
 */
export async function updateUserInfo(params){
    return $post("/admin/updateUserInfo",params);
}
export async function adminOptionList(params){
    return $post("/admin/adminOptionList",params);

}
export async function login(params){
    let {data}=await axios.post("/admin/login",params);
    return data;
}
export async function addUser(params){
    return $post("/admin/add",params);
}