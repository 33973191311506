import { reactive, ref } from "vue";
import {getRCR,rcrTongguo} from "@/api/user"
import { ElMessage } from "element-plus";
import {useBase} from "@/use/base"
export function useRCR(){
    const searchData=reactive({
        phone:{
            label:"手机号",
            value:"",
            type:"text"
        }
    });
    const headers=[
        {label:"ID",prop:"Id"},
       
        {label:"所属门店",prop:"name"},
        {label:"手机号",prop:"phone"},
        {label:"订单号",prop:"order_num"},
        {label:"申请日期",prop:"create_time"},
        {label:"审核日期",prop:"apply_time"},
        {label:"审核状态",prop:"state"},
        {label:"操作",prop:"caozuo"},
    ];
    const data=ref([])
    getRCR().then(res=>{
        data.value=res.data;
    })
    const tongguo=async (item)=>{
        const {code ,msg}=await rcrTongguo(item.Id);
        if(code==200){
            item.state=1;
            ElMessage.success(msg)
        }else{
            ElMessage.error(msg)
        }
    }
    const {search,tableObjet}=useBase(getRCR,searchData,false,true)
    return {searchData,headers,search,tableObjet,tongguo}
}