<template>
    <div>
        <XIASearchBar :searchData="searchData" />
        <div class="title" >还车审核</div>
        <TableComponent :headers="headers" :tableObjet="tableObjet" :search="search">
            <template #state="item">
                {{item.row.state==0?"待审核":"已通过"}}
            </template>
            <template #caozuo="item">
                    <el-button @click="tongguo(item.row)" v-if="item.row.state==0">通过</el-button>
            </template>
        </TableComponent>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useRCR} from "@/use/returnCarReview"
const {search,searchData,headers,tableObjet,tongguo}=useRCR();
</script>

<style lang="scss" scoped>

</style>