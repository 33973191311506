<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search"/>
        <div class="title">商户资金流水</div>
        <TableComponent :tableObjet="tableObjet" :headers="headers">
            <template #type="scope">
                {{ getType(scope.row.type) }}
            </template>
        </TableComponent>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useAdminCahFlow} from "@/use/finance"

const {searchData,headers,tableObjet,search}=useAdminCahFlow();

const getType = (type) => {
    const types = ["", "收取押金", "收取租金","押金退回","平台收取分成","提现申请","提现驳回"]
    return types[type]
}
</script>

<style lang="scss" scoped>

</style>