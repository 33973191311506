<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">用户列表</div>
        <TableComponent :data="tableData" :headers="headers" :currPage="currPage" :dataCount="dataCount">

            <template #backlist="item">

                <el-switch v-model="item.row.backlist" disabled
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
            </template>
            <template #v_state="item">
                {{getVState(item.row.v_state)}}
            </template>
            <template #caozuo="item">
               
                    <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" :icon="InfoFilled"
                        icon-color="#626AEF" title="是否确定?" @confirm="addBacklist(item.row)"
                        @cancel="cancelEvent">
                        <template #reference>
                            
                            <el-button type="danger" round size="small" >{{(item.row.backlist?'加入黑名单':'解除黑名单')}}</el-button>
                        </template>
                    </el-popconfirm>
                
                
                <!-- <el-button type="info" icon="Document" circle @click="handleInfo(scope.$index, scope.row)" />
                <el-button @click="handleEdit(scope.$index, scope.row)" type="primary" icon="Edit" circle />
                <el-button type="danger" icon="Delete" circle @click="handleDelete(scope.$index, scope.row)" /> -->
            </template>
        </TableComponent>
    </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue';
import { useUserIndex,useAddBackList } from "@/use/user"
import XIASearchBar from "@/components/XIA-SearchBar.vue";
const { searchData, tableData, headers, dataCount, currPage, search } = useUserIndex();
const {addBacklist}=useAddBackList();
const getVState=(state)=>{
    const states=['未提交','待审核','已审核']
    return states[state]
}
</script>

<style lang="scss" scoped>

</style>