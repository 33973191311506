
import { reactive, ref } from "vue";
import {useRule} from "@/use/rule"
import {addUser,adminOptionList} from "@/api/admin"
import { ElMessage } from "element-plus";
/**
 * 
 * @param {number} adminType 商户类型 0忽略 2加盟商 3门店  
 * @returns {Array} 
 */
export function useGetAdminListForOption(adminType=0){
        const admimOption=ref([]);
        const params={adminType}
        adminOptionList(params).then(data=>{
            admimOption.value=data.data.map(i=>{
                return {
                    label:i.name,
                    value:i.id
                }
            })
        })
        return {admimOption}
}

export function useAddAdmin(){
const {admimOption}=useGetAdminListForOption(3);
const {ruleList_option}=useRule();
const formData=reactive({
user_name:{
    type:'text',
    label:"账户名",
    require:true,
    placeholder:"请输入账户名",
    value:"",
},
parent:{
    type:'select',
    label:"绑定门店",
    options:admimOption,
    value:"",
    placeholder:"请选择门店，业务经理和平台客服不需要选择"
},
rule:{
    type:'select',
    label:"角色类型",
    require:true,
    options:ruleList_option,
    value:"",
    placeholder:"请选择角色类型"
},
phone:{
    type:'text',
    label:"手机号",
    require:true,
    value:"",
    placeholder:"请输入手机号"
},
pass_word:{
    type:'password',
    label:"密码",
    require:true,
    value:"",
    placeholder:"请输入密码"
},
pass_word2:{
    type:'password',
    label:"密码",
    require:true,
    value:"",
    placeholder:"请确认密码",
    equal:['pass_word']
    
}
})

const submit=async (parmas)=>{
   const {code,msg}= await addUser(parmas);
   if(code==200){
    ElMessage.success(msg)
   }else{
    ElMessage.error(msg)
   }
}
return {formData,submit}
}