import {leaseDataAdmin} from "@/api/statistics"
import {useBase} from "@/use/base"
import {reactive} from "vue"
export function useLeaseData(){

    const headers = [{ label: "ID", prop: "id" }, { label: "门店", prop: "name" },{label:"当前出租",prop:"now"},{label:"总数",prop:"total"}]
    const searchData = reactive({
        create_time: {
            label: "月份",
            value: "2022-12",
            placeholder: "时间段",
            type: "month"
        }
    })
    const {tableObjet,search}=useBase(leaseDataAdmin,searchData)

    return {tableObjet,search,headers,searchData}
}