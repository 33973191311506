<template>
    <el-drawer ref="drawerRef" v-model="isOpenDrawer" :direction="direction" :before-close="handleClose" @opened="open">
        <template #header>
            <div class="title">{{ drawOptions.title }}</div>
        </template>
        <template #default>
            <el-form>
                <el-form-item :label="item.label" v-for="(item, key) in drawOptions.formData" :key="key">
                    <template v-if="item.type == 'text'">
                        <el-input v-model="item.value" :disabled="isEdit || !item.canEdit" />
                    </template>
                    <template v-else-if="item.type == 'select'">
                        <el-select :disabled="isEdit || !item.canEdit" v-model="item.value" clearable
                            :placeholder="item.placeholder" >
                            <el-option v-for="option in item.options" :key="option.value" :label="option.label"
                                :value="option.value" />
                        </el-select>
                    </template>
                    <template v-else-if="item.type == 'switch'">
                        <el-switch v-model="item.value" :disabled="isEdit || !item.canEdit"
                            :active-text="item.placeholder"
                            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                    </template>
                    <template v-else-if="item.type == 'password'">
                        <el-input v-model="item.value" :disabled="isEdit || !item.canEdit" :class="'xia-' + key" @input="change(item)"
                            :placeholder="item.placeholder" type="password" />

                    </template>
                    <template v-else-if="item.type == 'image'">
                        <img :src="item.value" class="image" @click="chooseImage(key)" id="img"/>
                        <input type="file" style="display: none;" ref="image" :id="key"/>
                    </template>
                </el-form-item>

            </el-form>
        </template>
        <template #footer>
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="sumbit">提交</el-button>
        </template>
    </el-drawer>
</template>

<script setup>
import { ElMessage } from "element-plus";
import { ref, onMounted, defineProps, inject } from "vue"
const direction = ref("rtl")
const drawerRef = ref()
const prop = defineProps(['isEdit'])
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")
const image=ref()
const cancel = () => {
    drawerRef.value.close()
}
const sumbit = () => {
    let data={};
    for(let  key in drawOptions.formData){
       if(drawOptions.formData[key].file)
       {
        data[key]=drawOptions.formData[key].file
       }else{
        data[key]=drawOptions.formData[key].value
       }
    }
    drawOptions.postApi(data).then(res=>{
        if(res.code!=200){
            ElMessage.error(res.msg)
        }else{
            ElMessage.success(res.msg)
        }
    })

    
}
const handleClose = () => {
    drawerRef.value.close()
}

const open = async () => {
    
}
const onchange=(e)=>{
    var file = e.target.files[0];
	if(window.FileReader){
		var fr = new FileReader();
		fr.readAsDataURL(file);
		fr.onloadend = function(){
           for(let key in drawOptions.formData){
            if(key==e.target.id){
                drawOptions.formData[key].value=fr.result
                drawOptions.formData[key].file=file
                
            }
           }
		}
	}
}
const chooseImage=(key)=>{
    image.value.forEach(input => {
        input.onchange=onchange
        input.id===key&&input.click()
    });

}
</script>

<style lang="scss" scoped>
.image{
    width: 100px;
    height: 100px;
    border-radius: 20px;
}
</style>