import { ref,reactive} from "vue"
import {index,storeOrderTop10,leaseInfo,day7} from "@/api/home"

export function useHome(){
    const homeData=reactive({
        leaseInfo:{

        }
    })
    index().then((res)=>{
        let data=res.data;
        homeData.userCount=data.userCount;
        homeData.totalRevenue=data.totalRevenue;
        homeData.adminCount=data.adminCount;
        homeData.storeCount=data.storeCount;
    })
    storeOrderTop10().then(res=>{
        let data=res.data;
        homeData.storeOrderTop10=data
    })
    leaseInfo().then(res=>{
        let data=res.data;
        homeData.leaseInfo=data
    })
    day7().then(res=>{
        let data=res.data;
        homeData.day7=data
    })
    return {homeData}
}