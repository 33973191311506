<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search"/>   
        <TableComponent :headers="headers" :tableObjet="tableObjet">
            <template #type="scope">
            {{getType(scope.row.type)}}
            </template>

        </TableComponent>
    </div>
</template>

<script setup>
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import TableComponent from '@/components/TableComponent.vue';
import {useSysLog} from "@/use/sysLog"
const {headers,tableObjet,search,searchData}=useSysLog();
const getType=(type)=>{
const types=["","权限认证",""]
return types[type]
}
</script>

<style lang="scss" scoped>

</style>