import { $post } from "@/api/base"

export async function index(params) {
    return $post("home/index", params)
}
export async function storeOrderTop10(params) {
    return $post("home/storeOrderTop10", params)
}
export async function leaseInfo() {
    return $post("home/leaseInfo")
}

export async function day7() {
    return $post("home/day7")
}


