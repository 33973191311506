import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'
import dataV from '@jiaminghi/data-view'


import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { createPinia } from 'pinia'

import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

axios.defaults.baseURL=process.env.VUE_APP_BASE_URL
const app = createApp(App)
app.use(createPinia())
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus, {
  locale: zhCn,
})
app.use(dataV)
app.use(router).mount('#app')