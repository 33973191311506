<template>
  <div class="drawlayout">
   <RouterLink to="/">
    <div class="title2">
      积木捷行租车后台管理系统
    </div>
   </RouterLink>
    <el-scrollbar :height="height">
    <el-menu active-text-color="#409EFF" background-color="#545c64" class="el-menu" default-active="/"
      text-color="#fff"  :router="true" @select="selectMenu">
      <el-menu-item index="/">
        <el-icon>
          <House />
        </el-icon>控制台
      </el-menu-item>
      <el-sub-menu v-for="menu in menus" :key="menu.url" :index="menu.url">
        <template #title>
          
          <span>{{ menu.name }}</span>
        </template>
        <el-menu-item :index="menu.url + '/' + submenu.url" v-for="submenu in menu.menuItems" :key="submenu.url" >
          {{ submenu.name }}</el-menu-item>
      </el-sub-menu>


    </el-menu>
</el-scrollbar>

  </div>

</template>



<script setup>
import { ref, onMounted } from "vue";
import {useGetMenuList} from "@/use/menu"
import {useMainStore} from "/store/mainStore.js"
const {menuTab}=useMainStore();
const height=ref("");
const {menus} = useGetMenuList();
onMounted(async () => {
  height.value=document.body.clientHeight-50;
  
})

const selectMenu=(index,indexPath,item,routeResult)=>{
  menus.value.forEach(menu=>{
    if(menu.url==indexPath[0]){
      menu.menuItems.forEach(sub=>{
        if(menu.url+"/"+sub.url==index){
          if(menuTab.length>5)
          {
            menuTab.pop()
          }
          let isopen=false
          for(let i of menuTab){
            if(i.url==index){

              isopen=true
              
            }
          }
          if(!isopen)
          menuTab.push({label:sub.name,url:index})
        }
      })
    }
  })
}
</script>
<style lang="scss" scoped>
.drawlayout {
  height: 100vh;
  background-color: #545c64;
  overflow: hidden;
}

.title2 {
  color: var(--el-color-primary) !important;
  font-size: 16px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  
}

</style>