import { reactive, ref, watch } from "vue";
import { addBillingRule, getBillingRules,getBillingRulesOptions } from "@/api/billingrule"
import { useGetStoreList } from "@/use/store"
import { useBase } from "@/use/base"
import { ElMessage } from 'element-plus'
export function useAddBillingRule() {
    
    const { stores_option } = useGetStoreList();
   
    const data = reactive({

        store_id: {
            type: "select",
            label: "所属门店",
            value: "",
            require: true,
            placeholder: "请选择门店",
            options: stores_option
        },
        rule_name: {
            type: "text",
            label: "方案名称",
            value: "",
            require: true,
            placeholder: "请输入方案名称",

        },
        // type: {
        //     type: "select",
        //     label: "租赁类型",
        //     value: "",
        //     require: true,
        //     placeholder: "请选择租赁类型",
        //     options: [
        //         {
        //             label: "按周",
        //             value: 1
        //         },
        //         {
        //             label: "按月",
        //             value: 2
        //         }
        //     ]
        // },
        price: {
            placeholder: "请输入物品的租金",
            type: "text",
            label: "30天租金",
            value: "499",
            require: true
        },
        d15_price: {
            placeholder: "请输入物品的租金",
            type: "text",
            label: "15天租金",
            value: "299",
            require: true
        },
        // min_lease_time: {
        //     placeholder: "请输入起租时长",
        //     type: "text",
        //     label: "起租时长",
        //     value: "",
        //     require: true
        // },
        remark: {
            placeholder: "请输入备注",
            type: "text",
            label: "备注",
            value: "",
            require: true
        },

        // big_img: {
        //     placeholder: "请选择物品的大图",
        //     type: "file",
        //     label: "物品大图",
        //     value: "",
        //     name: "big_img",
        //     require: true
        // },
        // min_img: {
        //     placeholder: "请选择物品的略缩图",
        //     type: "file",
        //     label: "物品略缩图",
        //     value: "",
        //     name: "min_img",
        //     require: true
        // }
    })
    const submit = (params) => {
        addBillingRule(params).then(res => {
            if (res.code == 200) {
                ElMessage.success({ type: "success", message: res.msg })
            } else {
                ElMessage.error(res.msg)
            }
        })
    }
    return { data, submit }

}
/**
 * 获取计费规则列表
 * @returns 
 */
export function useGetBillingRules(store_id=null) {
    const headers = [

        {
            prop: "id",
            label: "ID"
        },
        {
            prop: "name",
            label: "所属门店"
        },
        {
            prop: "rule_name",
            label: "规则名称"
        },
        {
            prop: "price",
            label: "30天价格"
        },
        {
            prop: "d15_price",
            label: "15天价格"
        },
        {
            prop: "remark",
            label: "备注",

        },
        {
            prop: "caozuo",
            label: "操作"
        }
    ];
    const searchData = reactive({})
    if(store_id){
        watch(store_id,()=>{
            searchData.store_id=store_id.value
        })
    }
    const { tableObjet, search } = useBase(getBillingRules, searchData, false, true)
    return { headers, search, tableObjet, searchData }

}
/**
 * 根据门店id，获取这个门店的计费规则，放选项形式
 * @param {number} store_id 
 */
export function useBillingRulesOption(store_id){
    const billingRule_options=ref([])
    watch(store_id,i=>{
        getBillingRulesOptions(i).then(res=>{
            let data=res.data
            billingRule_options.value=[]
            data.forEach(item=>{
                billingRule_options.value.push({
                    label:item.rule_name,
                    value:item.id
                })
            })
        })
    })
    
    return {billingRule_options}

}