<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">租赁记录</div>
        <TableComponent :data="data" :headers="headers" :currPage="currPage" :dataCount="dataCount">
            <template #state="item">
                <span :class="'state' + item.row.state">
                    {{ state[item.row.state] }}
                </span>
            </template>
            <template #caozuo="item">
                <el-button @click="goDetail(item.row.device_number)" type="primary" round size="small">设备详情</el-button>

                <template v-if="item.row.state == 2 || item.row.state == 3">
                    <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" :icon="InfoFilled"
                        icon-color="#626AEF" title="是否确定结束订单?" @confirm="endOrder(item.row)"
                        @cancel="cancelEvent">
                        <template #reference>
                            <el-button type="warning" round size="small">结束订单</el-button>
                        </template>
                    </el-popconfirm>
                </template>
            </template>
        </TableComponent>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from "@/components/XIA-SearchBar.vue";
import { useLeaseList,useEndOrder } from "@/use/user"
import { useRouter } from "vue-router"
const router = useRouter()
const {endOrder}=useEndOrder()
const state = ["", "未支付", "租赁中", "超期未还", "已完成","订单取消"]
const { searchData, headers, data, search, dataCount, currPage } = useLeaseList();
const goDetail = (device_number) => {
    router.push({
        path: "/leaseitem/detail",
        query: {
            device_number
        }
    })
}
</script>

<style lang="scss" scoped>
.state3 {
    color: #f00;
}

.state4 {
    color: #0f0;
}

.state2 {
    color: #00f;
}
.state5 {
    color: #eee;
}
</style>