import { reactive, ref,watch } from "vue";
import {userindex,leaseList,cashFlow,endOrderForId,addBacklistForId,idReview,reviewId} from "@/api/user"
import {useGetAdminListForOption} from "@/use/admin"
import {useGetStoreList} from "@/use/store"
import { ElMessage } from "element-plus";
import { useBase } from "./base";
export function useUserIndex(){
    
    const dataCount=ref(0)
    const currPage=ref(1)
    const searchData=reactive({
        phone:{
            label:"手机号",
            value:"",
            type:"text",
            placeholder:"输入用户手机号"
        },
        user_name:{
            label:"姓名",
            value:"",
            type:"text",
            placeholder:"输入用户姓名"
        }
    });
    const tableData=ref([])
    const headers=ref([
        {
            label:"ID",
            prop:"id"
        },
        
        {
            label:"手机号",
            prop:"phone"
        },
        {
            label:"创建时间",
            prop:"create_time"
        },
        {
            label:"用户状态",
            prop:"backlist"
        },
        {
            label:"真实姓名",
            prop:"user_name"
        },
        {
            label:"身份证号",
            prop:"id_num"
        },
        {
            label:"实名认证",
            prop:"v_state"
        },
        {
            label:"操作",
            prop:"caozuo"
        }
    ])

    const _get=()=>{
        let params={};
        for(let i in searchData){
            params[i]=searchData[i].value
        }
        params.currPage=currPage.value;
        userindex(params).then(data=>{
            dataCount.value=data.data.dataCount
            tableData.value=data.data.data
            for(let i in tableData.value){
                tableData.value[i].backlist=tableData.value[i].backlist==0?true:false
            }
    
        })
    }
    _get();
    watch(currPage,()=>{

        _get();
    })
    const search=()=>{
        _get();
    }
    return {searchData,tableData,headers,dataCount,currPage,search}
}
export function useLeaseList(){
    const {admimOption}=useGetAdminListForOption(2)
    const admin_id=ref(0);
    const {stores_option}=useGetStoreList(admin_id);
    const currPage=ref(1)
    const dataCount=ref(0)
    const searchData=reactive({
        store_id:{
            label:"门店",
            value:"",
            type:"select",
            options:stores_option,
            placeholder: "请选择所属门店"
        },
        phone:{
            label:"手机号",
            value:"",
            type:"text",
            placeholder: "输入用户手机号"
        },
        lease_item_id:{
            label:"租赁物品编号",
            value:"",
            type:"text",
            placeholder: "输入租赁物品编号"
        },
        order_state:{
            label:"订单状态",
            value:"",
            type:"select",
            options:[{label:"未支付",value:1},{label:"租赁中",value:2},{label:"超期未还",value:3},{label:"已完成",value:4}],
            placeholder: "请选择订单状态"
        }
    })


    const headers=[
        {
            label:"ID",
            prop:"id"
        },
        {
            label:"所属门店",
            prop:"name"
        },
        {
            label:"用户手机号",
            prop:"phone"
        },
        {
            label:"租赁物品编号",
            prop:"device_number"
        },
        {
            label:"订单号",
            prop:"order_num"
        },
        {
            label:"租金",
            prop:"price"
        },
        {
            label:"订单状态",
            prop:"state"
        },
        {
            label:"创建时间",
            prop:"create_time"
        },
        {
            label:"到期时间",
            prop:"end_time"
        },
        {
            label:"操作",
            prop:"caozuo"
        }
    ]
    const data=ref([]);
    
    const search=()=>{
        _get()
    }
    const _get=()=>{
        let params={};
        params.currPage=currPage.value;
        for(let i in searchData){
            params[i]=searchData[i].value
        }
        leaseList(params).then(res=>{
            data.value=res.data.data;
            dataCount.value=res.data.dataCount;
        })
    }
    _get()
    return {searchData,headers,data,search,currPage,dataCount}
}
export function useCashFlow(){
    const searchData=reactive({
        phone:{
            label:"手机号",
            value:"",
            type:"text",
            placeholder: "输入用户手机号"
        }
    })
    const headers=[
        {
            label:"ID",
            prop:"id"
        },
        {
            label:"用户手机号",
            prop:"phone"
        },
        {
            label:"订单号",
            prop:"order_num"
        },
        {
            label:"金额",
            prop:"price"
        },
        {
            label:"类型",
            prop:"type"
        },
        {
            label:"时间",
            prop:"create_time"
        },
        {
            label:"备注",
            prop:"remark"
        }
    ]

    const {search,tableObjet}=useBase(cashFlow,searchData)
   
   
    
    return {searchData,headers,tableObjet,search}
}

export function useEndOrder(){

    const endOrder=(item)=>{
        endOrderForId(item.id).then(res=>{
            if(res.code==200){
                ElMessage.success(res.msg)
                item.state=4
            }else{
                ElMessage.error(res.msg)
            }
        }).catch(()=>{
                ElMessage.error("结束订单失败，请重试！")
        })
    }
    return {endOrder}

}
export function useAddBackList(){
    const addBacklist=(item)=>{
        addBacklistForId(item.id).then(res=>{
            if(res.code==200){
                ElMessage.success(res.msg)
                item.backlist=!item.backlist
            }else{
                ElMessage.error(res.msg)
            }
        }).catch(()=>{
            ElMessage.error("结束订单失败，请重试！")
        })

    }
    return {addBacklist}
}
export function useIdReview(){
    const searchData=reactive({
        phone:{
            label:"手机号",
            value:"",
            type:"text"
        }
    })
    const {tableObjet,search}=useBase(idReview,searchData)

    const headers=[{label:"ID",prop:"id"},{label:"姓名",prop:"user_name"},{label:"身份证",prop:"id_num"},{label:"操作",prop:"caozuo"}];
    const review=(item,state=2)=>{
        reviewId({id:item.id,state}).then(res=>{
               if(res.code==200){
                ElMessage.success(res.msg)
                item.v_state=2
               }else{
                ElMessage.error(res.msg)
               }
        })
    }
    return {searchData,headers,tableObjet,search,review}
}