<template>

    <div class="storeindex">
        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">门店列表</div>
        <TableComponent :headers="headers" :data="stores" :dataCount="dataCount" :currPage="currPage">
            <template #state="item">

                <el-switch v-model="item.row.state" disabled
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />


            </template>
            <template #image="scope">
               <img :src="'http://127.0.0.1/storage/'+scope.row.image" alt="">
            </template>
            <template #caozuo="scope">
                <el-button @click="handleEdit(scope.$index, scope.row)" type="primary" icon="Edit" circle />

                <!-- <el-button @click="storeConfig(item.row)">
                    运营配置
                </el-button> -->
            </template>
            
        </TableComponent>
    </div>
</template>
<script setup>
import {reactive,inject} from "vue"
import XIASearchBar from "@/components/XIA-SearchBar.vue";
import {updateStoreInfo} from "@/api/store"
import TableComponent from '@/components/TableComponent.vue';
import { useGetStoreList } from "@/use/store"
const { stores, switchState, updateInfo, storeConfig, dataCount, currPage, search, searchData } = useGetStoreList();
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")


const headers = [
    {
        prop: "id",
        label: "门店ID"
    },
    {
        prop: "name",
        label: "门店名"
    },
    {
        prop: "address",
        label: "门店地址"
    },
    {
        prop: "image",
        label: "门店图片"
    },
    {
        prop: "lat",
        label: "lat"
    },
    {
        prop: "lng",
        label: "lng"
    },
    {
        prop: "state",
        label: "运营状态"
    },
    {
        prop: "caozuo",
        label: "操作"
    }
]
const handleEdit = (index, item) => {
    const formData = reactive({
        id: {
            label: "ID",
            placeholder: "",
            value: item.id,
            canEdit: false,
            type: "text"
        },
        name: {
            label: "门店名称",
            
            value: item.name,
            canEdit: true,
            type: "text"
        },
        address: {
            label: "门店地址",
            
            value: item.address,
            canEdit: true,
            type: "text"
        },
        image: {
            label: "大图",
            
            value: "http://127.0.0.1/storage/"+item.image,
            canEdit: true,
            type: "image"
        },
        lat: {
            label: "纬度",
            
            value: item.lat,
            canEdit: true,
            type: "text"
        },
        lng: {
            label: "经度",
            
            value: item.lng,
            canEdit: true,
            type: "text"
        },
        state: {
            label: "运营状态",
            value: item.state == 1 ? true : false,
            placeholder: "点击开启或者禁用",
            canEdit: true,
            type: "switch"
        }
    })
    drawOptions.isEdit = true
    //drawOptions.getApi=getUserInfo
    drawOptions.postApi = updateStoreInfo
    drawOptions.formData = formData
    drawOptions.title = "修改门店信息"
    isOpenDrawer.value = true
}

</script>
<style scoped lang="scss">
img{
    width: 200px;
    height: 100px;
}
</style>