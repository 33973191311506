<template>
    <XIASearchBar @search="search" :searchData="searchData"></XIASearchBar>
    <TableComponent :headers="headers" :tableObjet="tableObjet">
        <template #caozuo="i">
        
        <el-button @click="review(i.row,2)" v-if="i.row.v_state==1">通过</el-button>
        <el-button @click="review(i.row,3)" v-if="i.row.v_state==1">驳回</el-button>
        </template>
    </TableComponent>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue'
import XIASearchBar from '@/components/XIA-SearchBar.vue'
import {useIdReview} from "@/use/user"
const {search,searchData,tableObjet,headers,review}=useIdReview()
</script>
<style scoped lang="scss">
</style>