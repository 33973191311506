<template>
    <div>
        <FormComponent @submit="submit" title="运营配置" canEdit="true" :data="data" :isUpdateForm="true"></FormComponent>
    </div>
</template>

<script setup>
import FormComponent from '@/components/FormComponent.vue';
import { reactive, ref, watch, watchEffect,onMounted } from 'vue';
import { useGetStoreList } from "@/use/store"
import { getStoreConfig, updateStoreConfig } from "@/api/store"
import { ElMessage } from 'element-plus';
import {useMainStore} from "../../../store/mainStore"
const mainStore=useMainStore();
const admin_id = ref(mainStore.currSelectAdmin_id);
const store_id = ref(mainStore.currSelectStore_id);
const { stores_option } = useGetStoreList(admin_id);
const data = reactive({

    store_id: {
        label: "门店",
        type: "select",
        value: null,
        require:true,
        placeholder: "请选择门店",
        options: stores_option
    },
    server_fee: {
        label: "分成占比",
        type: "text",
        value: null,
        require:true,
    },
    open: {
        label: "运营开关",
        type: "switch",
        
        value: true
    },
    car_change_review: {
        label: "换车审核",
        type: "switch",
        
        value: true
        
    },
    id_check: {
        label: "实名认证",
        type: "switch",
        
        value: true
    },
    pay_type: {
        label: "支付方式",
        type: "select",
        placeholder: "请选择支付方式",
        value: null,
        require:true,
        options: [
            {
                label: "线上",
                value: 1
            },
            {
                label: "线下",
                value: 2
            }
        ]
    }

})



watchEffect(() => {
    if (data.store_id.value)
        store_id.value = data.store_id.value
        mainStore.currSelectStore_id=data.store_id.value

})

watch(store_id, async (i) => {
    const res = await getStoreConfig(i);
    data.open.value = !!res.data.open
    data.car_change_review.value = !!res.data.car_change_review
    data.id_check.value = !!res.data.id_check
    data.pay_type.value = res.data.pay_type
    data.server_fee.value = res.data.server_fee

    
})
const submit = async (params) => {
    const { code, msg } = await updateStoreConfig(params);
    if (code == 200) {
        ElMessage.success(msg)
    } else {
        ElMessage.error(msg)
    }
}
</script>

<style lang="scss" scoped>
</style>