import {$post} from "./base"

export async function orderIndex(params){
    return $post("/order/orderIndex",params)
}

export async function orderReview(order_num){
    const params=new FormData();
    params.append("order_num",order_num);
    return $post("/order/orderReview",params);
}
export async function transferreview(order_num){
    const params=new FormData();
    params.append("order_num",order_num);
    return $post("/order/transferreview",params);
}
export async function transferReviewConfirm(params){
   
    return $post("/order/transferReviewConfirm",params);
}

