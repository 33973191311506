<template>
    <div class="homepage">


        <div class="cardlist">

            <div class="card" v-if="store.user_type==1">
                <div>
                    <el-icon size="80px" color="#5677fc">
                        <User />
                    </el-icon>
                </div>
                <div>
                    <div class="cardtitle">
                        用户数量
                    </div>
                    <div class="num">
                        {{ homeData.userCount }}
                    </div>
                </div>
            </div>
            <div class="card">
                <div>
                    <el-icon size="80px" color="#fc5677">
                        <Histogram />
                    </el-icon>
                </div>
                <div>
                    <div class="cardtitle">
                        总营收
                    </div>
                    <div class="num">
                        {{ homeData.totalRevenue }}￥
                    </div>
                </div>
            </div>
            <div class="card">
                <div>
                    <el-icon size="80px" color="#3f3">
                        <Aim />
                    </el-icon>
                </div>
                <div>
                    <div class="cardtitle">
                        设备投放数
                    </div>
                    <div class="num">
                        {{ homeData.adminCount }}
                    </div>
                </div>
            </div>

            <div class="card" v-if="store.user_type==1">
                <div>
                    <el-icon size="80px" color="#f5f">
                        <Odometer />
                    </el-icon>
                </div>
                <div>
                    <div class="cardtitle">
                        总门店
                    </div>
                    <div class="num">
                        {{ homeData.storeCount }}
                    </div>
                </div>
            </div>

        </div>
        <div class="chartTitle">
            门店营收统计
        </div>
        <div class="flex">

            <div id="chart" v-show="store.user_type==1">


            </div>
            <div id="chart4">

            </div>
        </div>

        <div class="chartTitle">
            订单流水
            
        </div>
        <div id="chart3">

        </div>

    </div>
</template>
<script setup>
import * as echarts from 'echarts';
import { onMounted, ref, watch } from "vue";
import { useHome } from "@/use/home"
import { useMainStore } from "/store/mainStore.js"
const store = useMainStore();
const { homeData } = useHome();
const myChart = ref({})
const myChart3 = ref({})
const myChart4 = ref({})
/**
 * 销毁 Echart实例
 */
const dispose = () => {
    echarts.init(document.getElementById("chart")).dispose();
    
    echarts.init(document.getElementById("chart4")).dispose();
    echarts.init(document.getElementById("chart3")).dispose();
}
/**
 * 初始化 Echart实例
 */
const init = () => {
    myChart.value = echarts.init(document.getElementById("chart"))
    myChart4.value = echarts.init(document.getElementById("chart4"))
    myChart3.value = echarts.init(document.getElementById("chart3"))
}
const setOption1 = (data=[]) => {
    myChart.value.setOption({
        title: {
            text: '门店收入排行',
            subtext: 'top10',
            left: 'left'
        },
        legend: {
            top: 'bottom'
        },
        toolbox: {
            show: true,
            feature: {
                mark: { show: true },
                saveAsImage: { show: true }
            }
        },
        series: [
            {
                name: 'Nightingale Chart',
                type: 'pie',
                radius: [50, 250],
                center: ['50%', '50%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 8
                },
                data
            }
        ]
    })
}

const setOption3 = (data) => {
    
    myChart3.value.setOption({
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: data.date
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: data.value,
                type: 'line',
                areaStyle: {}
            }
        ]
    })
}
const setOption4 = () => {

    myChart4.value.setOption({
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '5%',
            left: 'center'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '40',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: homeData.leaseInfo.idleCount, name: '闲置' },
                    { value: homeData.leaseInfo.leaseCount, name: '租赁中' },
                    { value: homeData.leaseInfo.noRetrunCount, name: '超期未还' },
                ]
            }
        ]
    })
}

/**
 * 设置 Echart 数据
 */
const setOptions = () => {
    setOption1()
    
    setOption3()
    setOption4()
}
onMounted(() => {
    dispose();
    init();
    //setOptions();
})
watch(()=>homeData.storeOrderTop10,(data)=>{
    setOption1(data)
})
watch(()=>homeData.leaseInfo,(data)=>{
    setOption4(data)
})
watch(()=>homeData.day7,(data)=>{
    setOption3(data)
})
</script>
<style lang="scss" scoped>
.homepage {
    .cardlist {
        display: flex;
        width: 100%;
        margin-top: 10px;
        height: 110px;
    }

    .card {
        flex: 1;
        margin-right: 50px;
        background-color: #fff;
        width: 350px;
        height: 100px;
        display: flex;
        border-radius: 10px;
        box-shadow: 5px 5px 5px #aaa;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        transition: all 300ms ease-in-out;

        &:hover {
            width: 360px;
            height: 110px;
            box-shadow: 15px 15px 15px #aaa;
        }

        div {
            text-align: center;
        }

        .cardtitle {
            margin-bottom: 20px;
            color: var(--el-color-primary);
        }

        .num {
            font-size: 30px;
        }
    }
}
.chartTitle {
    background-color: var(--el-color-primary);
    color: #fff;
    margin: 10px 20px;
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
}

#chart,
#chart3,
#chart4,
#chart5,
#chart2 {
    flex: 1;
    height: 500px;
    border-radius: 5px;
    margin: 10px 20px;
    background-color: #fff;
    box-shadow: 5px 5px 5px #eee;
    padding: 20px;
}

.flex {
    display: flex;
}
</style>