import { reactive, watch, ref, inject } from "vue"
import { useGetAdminListForOption } from "@/use/admin"
import { useGetStoreList } from '@/use/store'
export function _post(api, tableObjet, searchData) {
    let params = {};
    for (let i in searchData) {
        let item = searchData[i].value;
        params[i] = item
        if (Array.isArray(item)) {
            params[i] = item.map(o => {
                return o instanceof Date ? o.getTime() : o
            })
        }

    }
    params.currPage = tableObjet.currPage
    
    api(params).then((res) => {
        if (res.code == 200) {
            tableObjet.data = res.data.data
            tableObjet.dataCount = res.data.dataCount
        }
    })
}

export function useBase(api, searchData, needAdminOptios = false, needStoreOpitons = false) {
    const tableObjet = reactive({
        data: [],
        dataCount: 0,
        currPage: 1
    })
    const { baseSearchData } = useBaseSearchData()
    if (needAdminOptios) {
        searchData.admin_id = baseSearchData.admin_id
    }
    if (needStoreOpitons) {
        searchData.store_id = baseSearchData.store_id
    }
    const search = () => {
        _post(api, tableObjet, searchData)
    }
    watch(() => tableObjet.currPage, () => {
        _post(api, tableObjet, searchData)
    })
    _post(api, tableObjet, searchData)
    return { search, tableObjet }
}
/**
 * 最基本的搜索数据，商户，门店
 */
export function useBaseSearchData() {
    const admin_id = ref(0);
    const { admimOption } = useGetAdminListForOption(2)
    const { stores_option } = useGetStoreList(admin_id)
    const baseSearchData = reactive({
        admin_id: {
            label: "加盟商",
            value: "",
            placeholder: "请选择加盟商",
            options: admimOption,
            type: "select"
        },
        store_id: {
            label: "门店",
            value: "",
            placeholder: "请选择门店",
            options: stores_option,
            type: "select"
        },
    })
    watch(() => baseSearchData.admin_id.value, (id) => {
        admin_id.value = id;
    })
    return { baseSearchData }

}
/**
 * 呼出修改侧边栏
 */
export function useHandleEdit() {
    const isOpenDrawer = inject("isOpenDrawer")
    const drawOptions = inject("drawOptions")
    const handleEtid= (formData, updateApi,title) => {
        
        drawOptions.isEdit = true
        drawOptions.postApi = updateApi
        drawOptions.formData = formData
        drawOptions.title = title
        isOpenDrawer.value = true
    }
    return {handleEtid}

}