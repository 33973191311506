<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">提现总览</div>

        <div class="flex zonlan">
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    总
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{total_balance}}</div>
                    <div>
                        门店总金额
                    </div>
                </div>
            </div>
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    待
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{dai_balance}}</div>
                    <div>
                        待处理
                    </div>
                </div>
            </div>
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    已
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{yi_balance}}</div>
                    <div>
                        已提现金额
                    </div>
                </div>
            </div>
        </div>

        <div class="title">申请列表</div>
        <TableComponent :headers="headers" :tableObjet="tableObjet">

            <template #state="scope">
                {{ getState(scope.row.state) }}
            </template>
            <template #caozuo="scope">
                <div v-if="scope.row.state == 1">
                    <el-button @click="editHandle(scope.row, true)">通过</el-button>
                    <el-button @click="editHandle(scope.row, false)">驳回</el-button>
                </div>
            </template>
        </TableComponent>


    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useCashReview } from "@/use/finance"

const { search, searchData, headers, tableObjet, editHandle } = useCashReview();
const getState = (state) => {
    const states = ["", "待审核", "已完成", "已驳回"]
    return states[state]
}
</script>

<style lang="scss" scoped>
.zonlan {
    flex: 1;
    width: 100%;
    margin-top: 20px;

    .item {
        background-color: #fff;
        height: 100px;
        border-radius: 10px;
        margin: 0 50px;
        border:1px solid rgb(161, 215, 244);
        align-items: center;

        .zs {
            background-color: var(--el-color-primary);
            width: 10px;
            border-radius: 10px;
            margin-right: 50px;
            height: 100%;
        }
        .b{
            color:#999;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            border:5px solid var(--el-color-primary);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .c{
            text-align: center;
            color: #999;
            font-size: 13px;
            .price{
                margin-bottom: 10px;
                color: var(--el-color-primary);
                font-size: 25px;
            }
        }
    }

}
</style>