<template>
    <div>
        <FormComponent title="租赁物品投放" :data="formData" canEdit="true" @submit="createdLeaseItem"></FormComponent>
    </div>
</template>

<script setup>
import FormComponent from '@/components/FormComponent.vue';
import {useLeaseItem} from "@/use/leaseitems"

const {formData,createdLeaseItem}=useLeaseItem();
</script>

<style lang="scss" scoped>

</style>