import { reactive, ref,watch } from "vue";
import {orderIndex,orderReview,transferreview,transferReviewConfirm} from "@/api/order"
import { ElMessage } from "element-plus";
import {useBase} from "./base"
import {useGetAdminListForOption} from "@/use/admin"
import {useGetStoreList} from "@/use/store"
export function useOrderIndex(){
    const admin_id=ref(0);
    const {stores_option}=useGetStoreList(admin_id)
    
    const searchData=reactive({
        store_id:{
            label:"门店",
            value:"",
            placeholder:"请选择门店",
            options:stores_option,
            type:"select"
        },
        state:{
            label:"订单状态",
            value:"",
            placeholder:"选择订单状态",
            options:[
                {
                    value:"1",
                    label:"未支付"
                },
                {
                    value:"2",
                    label:"已支付"
                },
                {
                    value:"3",
                    label:"超期未还"
                },
                {
                    value:"4",
                    label:"已完成"
                }
                ,
                {
                    value:"5",
                    label:"已取消"
                }
            ],
            type:"select"
        },
        lease_item_id:{
            label:"设备号",
            value:"",
            placeholder:"请输入设备号",
            type:"text"
        },
        phone:{
            label:"手机号",
            value:"",
            placeholder:"请输入手机号",
            type:"text"
        },
        create_time:{
            label:"下单时间",
            value:"",
            placeholder:"时间段",
            type:"datePick"
        }
    })
    const headers=ref([
        {
            label:"ID",
            prop:"id"
        },
        {
            label:"用户手机号",
            prop:"phone"
        },
        {
            label:"所属门店",
            prop:"name"
        },
        {
            label:"订单号",
            prop:"order_num"
        },
        {
            label:"租赁设备编号",
            prop:"device_number"
        },
        {
            label:"订单状态",
            prop:"state"
        },
        {
            label:"订单类型",
            prop:"type"
        },
        {
            label:"创建时间",
            prop:"create_time"
        },
        {
            label:"订单到期时间",
            prop:"end_time"
        },
        {
            label:"支付时间",
            prop:"pay_time"
        },
        {
            label:"订单价格",
            prop:"price"
        },
        {
            label:"操作",
            prop:"caozuo"
        }
    ])
    const pay_review=async (item)=>{
        const {code ,msg}=await orderReview(item.order_num);
        if(code==200){
            ElMessage.success(msg);
            item.state=2;
        }else{
            ElMessage.error(msg);
        }
    }
    const {search,tableObjet} =useBase(orderIndex,searchData)
    return {searchData,headers,tableObjet,pay_review,search}
}

export function useChangeCar(){
    const headers=ref([
        {
            label:"ID",
            prop:"id"
        },
        {
            label:"用户手机号",
            prop:"phone"
        },
        {
            label:"所属门店",
            prop:"name"
        },
        {
            label:"订单号",
            prop:"order_num"
        },
        {
            label:"旧设备编号",
            prop:"old_device_number"
        },
        {
            label:"新设备编号",
            prop:"new_device_number"
        },
        {
            label:"状态",
            prop:"state"
        },
        {
            label:"申请换车时间",
            prop:"create_time"
        },
        {
            label:"订单到期时间",
            prop:"end_time"
        },
        {
            label:"操作",
            prop:"caozuo"
        }
    ])
    const searchData=reactive({

    })
    const {search,tableObjet}=useBase(transferreview,searchData,false,true)
    const commit=(item,state)=>{
        transferReviewConfirm({id:item.id,state}).then(res=>{
            if(res.code==200){
                item.state=state?2:3
                ElMessage.success(res.msg)
            }else{
                ElMessage.error(res.msg)
            }
        })
    }
    return {search,tableObjet,headers,searchData,commit}

}
