import axios from "axios"
import {$post} from "@/api/base"
export async function getStoreList(admin_id){
    const token=localStorage.getItem("token");
    let {data}=await axios.post("/store/index",{token,admin_id});

    return data;

}


export async function addStore(params){
    return $post("/store/add",params);

}
export async function getStoreConfig(store_id){
    
    let params=new FormData();
    params.append("store_id",store_id);
    return $post("/store/config",params);

}
export async function updateStoreConfig(params){
    
    return $post("/store/update",params);

}
export async function updateStoreInfo(params){
    
    return $post("/store/updateStoreInfo",params);

}
