import {$post} from "@/api/base"

export async function adminCash(){
    return $post("finance/adminCash")
}
/**
 * 提现规则配置
 *
 */
export async function cashRule(){
    return $post("finance/cashRule")
}

/**
 * 更新提现规则配置
 *
 */
 export async function updateCashRule(params){
    return $post("finance/updateCashRule",params)
}
export async function adminCashFlow(params){
    return $post("finance/adminCashFlow",params)
}

/**
 * 
 * 获取商户提现列表
 */
export async function cashReview(params){
    return $post("finance/cashReview",params)
}

export async function cashApply(params){
    return $post("finance/cashApply",params)
}
export async function cashReviewConfirm(params){
    return $post("finance/cashReviewConfirm",params)
}

export function freezeCash(params){
    return $post("finance/freezeCash",params)
}


