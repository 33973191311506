import { createRouter, createWebHashHistory } from 'vue-router'
import adminIndexPage from "@/pages/admin/indexPage.vue"
import addAdminPage from "@/pages/admin/addAdminPage.vue"
import storeIndexPage from "@/pages/store/indexPage.vue"
import addStorePage from "@/pages/store/addStorePage.vue"
import storeConfig from "@/pages/store/storeConfigPage.vue"


import refresh from "@/pages/refresh/RefreshPage.vue"


import homePage from "@/pages/home/homePage.vue"


import menuIndex from "@/pages/menu/menuIndex.vue"
import addMenu from "@/pages/menu/addMenu.vue"
import itemModel from "@/pages/leaseItems/itemModelpage.vue"
import billingRole from "@/pages/leaseItems/billingRulePage.vue"
import leaseItemDetail from "@/pages/leaseItems/leaseItemDetailPage.vue"
import addbillingrule from "@/pages/leaseItems/addBillingRulePage.vue"
import addLeaseItemPage from "@/pages/leaseItems/addLeaseItemPage.vue"
import leaseItem from "@/pages/leaseItems/leaseItemPage.vue"


import powerplan from "@/pages/leaseItems/powerPlanPage.vue"




import addItemModel from "@/pages/leaseItems/addItemModelpage.vue"
import roleIndex from "@/pages/role/roleIndex.vue"
import purchaseIndex from "@/pages/purchase/purchaseIndexPage.vue"
import supplierIndex from "@/pages/purchase/supplierIndexPage.vue"
import itemIndex from "@/pages/purchase/itemIndexPage.vue"
import addItem from "@/pages/purchase/addItemPage.vue"
import addSupplier from "@/pages/purchase/addSupplierPage.vue"
import addPurchase from "@/pages/purchase/addPurchasePage.vue"
import userIndex from "@/pages/user/userIndexPage.vue"
import userLeaseList from "@/pages/user/userLeaseListPage.vue"
import userCashflow from "@/pages/user/userCashFlowPage.vue"
import idReview from "@/pages/user/idReview.vue"

import adminCash from "@/pages/finance/adminCashPage"
import cashRule from "@/pages/finance/cashRulePage"
import cashReview from "@/pages/finance/cashReviewPage"
import adminCashFlow from "@/pages/finance/adminCashFlowPage"
import cashapply from "@/pages/finance/cashapply"
import freezeCash from "@/pages/finance/freezeCash"




import orderIndex from "@/pages/order/orderIndexPage.vue"
import returnCarReview from "@/pages/order/returnCarReviewPage.vue"
import transferreview from "@/pages/order/carChangeReviewPage.vue"

import leaseData from "@/pages/statistics/leaseData.vue"
import bigDataScreen from "@/pages/statistics/bigDataScreen.vue"





import sysLogIndex from "@/pages/syslog/index.vue"
const routes=[

  {
    path:"/",
    component:homePage
  },
  {
    path:"/statistics",
    children:[
      {
        component:leaseData,
        path:"leasedata"
      },
      {
        component:bigDataScreen,
        path:"bigDataScreen"
      }
    ]
  },
  {
    path:"/syslog",
    children:[
      {
        component:sysLogIndex,
        path:"index"
      }
    ]
  },
  {
    path:"/xiarefresh",
    component:refresh
  },
  {
    path:'/finance',
    children:[
      {
        path:'cashapply',
        component:cashapply
      },
      {
        path:"admincash",
        component:adminCash
      },
      {
        path:"freezecash",
        component:freezeCash
      },
      
      {
        path:"cashrule",
        component:cashRule
      },
      {
        path:"cashreview",
        component:cashReview
      },
      {
        path:"admincashflow",
        component:adminCashFlow
      }
    ]
  },
  {
    path:"/order",
    children:[
      {
        path:"orderIndex",
        component:orderIndex
      },
      {
        path:"returncarreview",
        component:returnCarReview
      },{
        path:'transferreview',
        component:transferreview
      }
    ]
  },
  {
    path:"/user",
    children:[
      {
        path:"index",
        component:userIndex
      },
      {
        path:"idReview",
        component:idReview
      },
      
      {
        path:"leaserecord",
        component:userLeaseList
      },
      {
        path:"cashflow",
        component:userCashflow
      }

    ]
  },
  {
    path:"/purchase",
    children:[
      {
        path:'index',
        component:purchaseIndex
      },
      {
        path:'addPurchase',
        component:addPurchase
      },
      
      {
        path:'supplier',
        component:supplierIndex
      },
      {
        path:"addSupplier",
        component:addSupplier
      },
      {
        path:'item',
        component:itemIndex
      },
      {
        path:"addItem",
        component:addItem
      }
    ]
  },
  {
    path:"/role",
    children:[
      {
        path:"index",
        component:roleIndex
      }
    ]
  },
  {
    path:"/leaseitem",
    children:[
      {
        path:"itmemodel",
        component:itemModel
      },
      {
        path:"detail",
        component:leaseItemDetail
      },
      
      {
        path:"addItemModel",
        component:addItemModel
      },
      {
        path:"add",
        component:addLeaseItemPage
      },
      {
        path:"index",
        component:leaseItem
      },
      {
        path:"billingRole",
        component:billingRole
      },
      {
        path:"addbillingrule",
        component:addbillingrule
      },
      
      {
        path:"powerplan",
        component:powerplan
      }

      
    ]
  },
  {
    path:'/menu',
    children:[
      {
        path:'index',
        component:menuIndex
      },
      {
        path:'add',
        component:addMenu
      }
    ]
  },
  {
    path:"/admin",
    children:[
      {
        path:"add",
        component:addAdminPage
      },
      {
        path:"index",
        component:adminIndexPage
      }
    ]
  },
  {
    path:"/store",
    
    children:[
      {
        path:"add",
        component:addStorePage
      },
      {
        path:"setting",
        component:storeConfig
      },
      
      {
        path:"index",
        component:storeIndexPage
      }
    ]
  }
];
// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]
import {a} from "./auto.js"
let b=a();
routes.push(...b)
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
