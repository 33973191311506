import axios from "axios"
import { ElMessage } from "element-plus";
let config = {
    headers: {'Content-Type': 'multipart/form-data'}
 }
/**
 * 
 * @param {*} url  请求地址不带 baseURL
 * @param {*} params  请求参数 
 * @returns {Object} {msg:"",code:"",data:{}}
 */
export async function $post(url,params=new FormData()){
    const token=localStorage.getItem("token");
    if(params instanceof FormData){
        params.append("token",token);
    }else{
        let p=new FormData();
        for(let i in params){
            p.append(i,params[i]);
        }
        params=p;
        params.append("token",token);
    }
    try {
        let {data}=await axios.post(url,params,config);
        
        return data;
    } catch (error) {
        return Promise.resolve({msg:"服务器错误",code:500})
    }

}
/**
 * 
 * @param {*} url  请求地址不带 baseURL
 * @param {*} params  请求参数 
 * @returns {Object} {msg:"",code:"",data:{}}
 */
 export async function $get(url,params=new FormData()){
    const token=localStorage.getItem("token");
    if(params instanceof FormData){
        params.append("token",token);
    }else{
        let p=new FormData();
        for(let i in params){
            p.append(i,params[i]);
        }
        params=p;
        params.append("token",token);
    }
    try {
        let {data}=await axios.get(url,params,config);
        return data;
    } catch (error) {
        return Promise.resolve({msg:"连接服务器超时",code:500})
    }

}