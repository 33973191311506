<template>
    <div class="title">
        出租率
    </div>
    <XIASearchBar :searchData="searchData" @search="search"/>

    <div>
        <TableComponent :headers="headers" :tableObjet="tableObjet"></TableComponent>
    </div>
</template>

<script setup>

import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';

import {useLeaseData} from "@/use/statistics"

const {search,searchData,tableObjet,headers}=useLeaseData()

</script>

<style lang="scss" scoped>

</style>