<template>

    <div>

        <div class="title">
            菜单列表
        </div>
        <el-tree 
        :data="menuList"   
        
        node-key="id" 
        :props="defaultProps" 
        :expand-on-click-node="false" >
        
        <template #default="{ node, data }">
        <span class="custom-tree-node">
          <span>{{ node.label }}</span>
          <span>
            <a v-if="data.children"> <span>排序：</span><input type="text" :value="data.order"/></a>
            <a @click="append(data)" v-if="!data.show"> 显示 </a>
            <a @click="append(data)" v-else style="margin-left: 8px"> 隐藏 </a>
            <a style="margin-left: 8px" @click="remove(node, data)"> 删除 </a>
          </span>
        </span>
      </template>
        </el-tree>
    </div>
</template>

<script setup>
import { getMenuList } from "@/api/menu.js"

import { ref, reactive, onMounted } from "vue"

const customNodeClass = (data, node) => {
  if (data.order) {
    return 'is-penultimate'
  }
  return null
}
const defaultProps = {
    children: 'children',
    label: 'label',
    class: customNodeClass
}
let menuList = ref([]);

onMounted(async () => {
  let isShowAll=true;
  
    const { data } = await getMenuList(isShowAll);
    getMenuList(isShowAll).then()
    menuList.value = data.map(i => { let children = []; if (i.menuItems) { children = i.menuItems.map(j => { return { label: j.name, id: j.jd,show:j.show,order:j.order } }) } return { label: i.name, children, id: i.id,show:i.show,order:i.order } });

})
</script>
<style  lang="scss">

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  a{
    color: blueviolet;
  }
  input{
    width: 50px;
    border:1px solid blueviolet;
    border-radius: 35px;
    text-align: center;

  }
}
.el-tree{
    padding: 20px;
    border-radius: 15px;
    box-shadow: 15px 15px 15px #eee;
.is-penultimate{
    margin: 20px;
}
}
</style>