import {$post} from "@/api/base"
export async function add(params){
   let data= await $post("/leaseItem/add",params);
   return data;
}
export async function index(params){
    let data= await $post("/leaseItem/index",params);
    return data;
 }
 export async function itemInfo(params){
   let data= await $post("/leaseItem/itemInfo",params);
   return data;
}
export async function deviceCron(params){
   let data= await $post("/leaseItem/deviceCron",params);
   return data;
}
export async function lastLeaseUser(params){
   let data= await $post("/leaseItem/lastLeaseUser",params);
   return data;
}

export  function updateLeaseItem(params){
   
   return $post("/leaseItem/updateLeaseItem",params);
}

