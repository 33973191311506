import { defineStore } from 'pinia'
const token=localStorage.getItem("token");
const user_name=localStorage.getItem("user_name");
const curr_select_store_id=localStorage.getItem("curr_select_store_id");

const menuTab=[]
menuTab.push({label:"首页",url:"/"})

export const useMainStore = defineStore('main', {
  state: () => ({
    token,
    user_name,//当前登录用户名
    menuTab,//打开的菜单集合
    curr_select_store_id//当前选择的门店id
  }),

  getters: {
   
    doubleCount: (state) => state.counter * 2,
   
    
  },
  actions: {
    reset() {
      
      this.counter = 0
    },
  },
})