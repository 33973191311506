<template>
    <div>
       
        <div class="top">
           
            <div class='item'>
                <div class="left">
                    物品信息
                </div>
                <div class="right">
                    <div>
                        <span class="tag">租赁物品编号</span><span class="info">{{device_number}}</span>

                    </div>
                    <div>
                        <span class="tag">设备编号</span><span class="info">{{device_number}}</span>

                    </div>
                    <div>
                        <span class="tag">状态</span><span class="info">{{deviceIfno.online?'在线':'离线'}}</span>

                    </div>
                    <div>
                        <span class="tag">最后定位时间</span><span class="info">{{deviceIfno.posDt}}</span>

                    </div>
                    
                </div>
            </div>
            <div class='item'>
                <div class="left">
                    订单信息
                </div>
                <div class="right">
                    <div>
                        <span class="tag">最近租车人</span><span class="info">{{orderInfo.phone}}</span>

                    </div>
                    <div>
                        <span class="tag">租赁日期</span><span class="info">{{orderInfo.create_time}}</span>

                    </div>
                    <div>
                        <span class="tag">到期时间</span><span class="info">{{orderInfo.end_time}}</span>

                    </div>
                </div>
            </div>
            <div class='item'>
                <div class="left">
                    租赁物品控制
                </div>
                <div class="right">
                   
                    <div style="display: flex;">
                        <div>
                        <el-button @click="cron(11) " type="success">启动</el-button>
                        

                        </div>
                    <div>
                        <el-button @click="cron(2)" type="primary">开锁</el-button>

                    </div>
                    <div>
                        <el-button @click="cron(1)"  type="warning">关锁</el-button>

                    </div>
                    <div>
                        <el-button @click="cron(9)"  type="info">寻车</el-button>

                    </div>
                    <div>
                        <el-button @click="cron(20)"  type="danger">租车停用</el-button>
                        <el-button @click="cron(19)"  type="primary">租车启用</el-button>
                    </div>
                    
                    </div>
                    <div >
                      <span class="tag">车锁：</span>  <span :class="deviceIfno.lock?'r':'g'">{{deviceIfno.lock?"已锁车":"已开锁"}}</span>
                    </div>
                    <div>
                        <span class="tag">通电状态：</span><span :class="deviceIfno.power?'g':'r'">{{deviceIfno.power?"已通电":"未通电"}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bar">
            <el-button @click="carPosition">车辆位置</el-button>
        </div>
        <div id="allmap">

        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref,onUnmounted } from 'vue'
import {useRoute,useRouter} from "vue-router"
import {itemInfo,deviceCron,lastLeaseUser} from "@/api/leaseItem"
import { ElNotification } from 'element-plus';
const device_number=ref()
const {query}=useRoute()
const router=useRouter()
const deviceIfno = ref({})
const orderInfo = ref({})
device_number.value=query.device_number
if(!query.device_number){
    router.push({path:"/leaseitems/index"})
}
const cron=(i)=>{
    let title="开锁"
    switch(i){
        case 1:
            title="关锁"
            break;
            case 2:
            title="开锁"
            break;
            case 11:
            title="启动"
            break;
            case 9:
            title="寻车"
            break;
            case 20:
            title="停用"
            break;
            case 19:
            title="启用"
            break;
    }
    ElNotification({
    title,
    message: '正在'+title,
    type: 'info',
  })
    deviceCron({code:i,device_number:device_number.value}).then(res=>{
        if(res.ret==1){
            ElNotification({
            title,
            message: title+"命令发送成功",
            type: 'success',
        })
        }
    })
}
let interval=setInterval(() => {
    itemInfo({device_number:device_number.value}).then(res=>{
        
        if(res.code==200){
            deviceIfno.value=res.data
            point.value = new BMapGL.Point(res.data.lonC, res.data.latC);
            
            addMarket(res.data.lonC,res.data.latC)
        }
    })
}, 2000);
onUnmounted(()=>{
 clearInterval(interval)
})
lastLeaseUser({device_number:device_number.value}).then(res=>{
if(res.code==200){
    orderInfo.value=res.data
}
})
let map = null
const point = ref(0);
const carPosition = () => {
    map.setCenter(point.value); // 设置地图中心点
}
const addMarket=(lonC,latC)=>{
    // 创建小车图标
    var myIcon = new BMapGL.Icon("/machine.png", new BMapGL.Size(50, 50));
    // 创建Marker标注，使用小车图标
    var pt = point.value;
    var marker = new BMapGL.Marker(pt, {
        icon: myIcon
    });
    // 创建地理编码实例      
    var myGeo = new BMapGL.Geocoder();   
// 根据坐标得到地址描述    
myGeo.getLocation(new BMapGL.Point(lonC,latC), function(result){      
    if (result){      
       
            // 创建信息窗口
    var opts = {
        width: 200,
        height: 100,
        title: '设备编号:'+device_number.value
    };
    var infoWindow = new BMapGL.InfoWindow( result.address, opts);
    // 点标记添加点击事件
    marker.addEventListener('click', function () {
        map.openInfoWindow(infoWindow, point.value); // 开启信息窗口
    });
    }      
});
   
    
    // 将标注添加到地图
    map.addOverlay(marker);
    map.addEventListener('click', function (e) {
        //alert('点击位置经纬度：' + e.latlng.lng + ',' + e.latlng.lat);
    });
    // // 绘制圆
    // var circle = new BMapGL.Circle(point.value, 500, {
    //     strokeColor: 'blue',
    //     strokeWeight: 2,
    //     strokeOpacity: 0.5
    // });
    // map.addOverlay(circle);
}
onMounted(() => {
    point.value = new BMapGL.Point(119.34961030051122, 26.10194818587548);

    // 百度地图API功能
    map = new BMapGL.Map("allmap");    // 创建Map实例
    map.centerAndZoom(point.value, 20);
    map.enableScrollWheelZoom(true);
    

    
})


const itemControll = reactive({

})
</script>

<style lang="scss" scoped>
.top {
    display: flex;

    .item {
        flex: 1;
        margin-left: 20px;
        display: flex;
        flex-direction: column;

        height: 20vh;
        background-color: #fff;
        border-radius: 15px;
        align-items: center;
        text-align: center;
        font-size: 20px;
       
        
        .left {
            width: 35%;
            background-color: #181818;
            border-radius: 15px;
            margin: 10px;
            color: #fff;
        }

        .right {
            text-align: left;
            flex: 1;
           
            
            div {
                margin-top: 10px;
                font-size: 18px;
                
                .r{
                    color: #fff;
                    background-color: palevioletred;
                    padding:  0 10px;
                    border-radius: 5px;
                }
                .g{
                    padding:  0 10px;
                    color: #fff;
                    border-radius: 5px;
                    background-color: greenyellow;
                }
            }
            button{
                margin-right: 10px;
            }
           
        }

        .tag {
            color: #999;
            font-size: 18px;
            width: 120px;
            display: inline-flex;
            margin-right: 25px;
           
        }
        .info{
            font-size: 18px;

        }

    }
}

.bar {
    margin-top: 20px;
}

#allmap {
    margin-left: 2%;
    margin-top: 5vh;
    width: 96%;
    height: 65vh;
    box-shadow: 15px 15px 15px #333;
}
</style>