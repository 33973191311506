import { getStoreList } from "@/api/store"
import { ElMessage } from "element-plus";
import { ref, watch,reactive } from "vue";
import {useGetAdminListForOption} from "@/use/admin"
import {useRouter} from "vue-router"
import {useMainStore} from "../../store/mainStore"
export function useStore(){

}
/**
 * 根据商户id 获取门店列表
 * @param {number} admin_id 
 * @returns 
 */
export  function useGetStoreList(admin_id=0){
    const {admimOption}=useGetAdminListForOption(2);
    const stores=ref([]);
    const stores_option=ref([])
    const currPage=ref(1);
    const dataCount=ref(0);
    const router=useRouter()
    const mainStore=useMainStore();
    //搜索框的数据
const searchData = reactive({
    name:{
        label: "门店名",
        type: 'text',
        placeholder: "输入门店名称",
        value:""
    }
})
_get()
    function _get(i){
        getStoreList(i).then((data)=>{
            if(data.code==200){
                stores.value=data.data.data;
                for(let i in stores.value){
                    stores.value[i].state=stores.value[i].state==1?true:false
                }
                dataCount.value=data.data.dataCount;
                stores_option.value=data.data.data.map(i=>{
                    return {
                        label:i.name,
                        value:i.id
                    }
                })
            }else{
                ElMessage.error(data.msg);
            }
        })
    }
    const switchState=(item)=>{
        item.state==1?item.state=0:item.state=1;
    }

    const storeConfig=(item)=>{
        mainStore.menuTab.set("运营配置","/store/setting")
        mainStore.currSelectAdmin_id=item.admin_id;
        mainStore.currSelectStore_id=item.id;
        router.push({path:"/store/setting"})
    }
    const updateInfo=(item)=>{
        console.log(item.admin_id,item.id);
    }
    const search=()=>{

    }

    

    return {stores,stores_option,switchState,dataCount,currPage,search,searchData,storeConfig,updateInfo}
}
