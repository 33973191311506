import {$post} from  "./base";

export async function addItemModel(parmas){
const data=await $post("itemmodel/add",parmas);
return data;
}
export async function getItemModelList(store_id){
    let fd=new FormData();
    fd.append("store_id",store_id);
    const data=await $post("itemmodel/index",fd);
    return data;
    }

    export async function upItemModelInfo(params){
  
        return $post("/itemmodel/upItemModelInfo",params);
     }